//
//  Breakpoints
//
$xsMinWidth: 0;
$smMinWidth: 600px;
$mdMinWidth: 960px;
$lgMinWidth: 1280px;
$xlMinWidth: 1920px;

//
//  Colors
//
$colorPrimary: #006064;
// $colorPrimary: #263238;
$colorSecondary: #3b3b3b;
$colorSectionBg: #f1f1f1;
$colorNavBg: #2c3e50;
$colorNavLink: #fff;
$colorPageTitle: $colorSecondary;
$colorPageBg: #ecf0f1;

//
//  Dimensions
//
$navBarHeight: 60px;
