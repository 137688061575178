//
// Footer
//

$colorFooterBg: #2c3e50;
$colorFooterText: #fff;

footer {
  display: block;
  background-color: $colorFooterBg;

  p, .link-contact {
    color: $colorFooterText;
  }

  .link-contact {
    font-size: 1.5rem;

    + .link-contact {
      margin-left: 15px;
    }
  }
  
  .copyright-info, .footer-contact {
    text-align: center;
  }

  @media(max-width: $smMinWidth) {
    .footer-contact {
      margin-top: 15px;
    }
  }

  @media(min-width: $smMinWidth) {
    .copyright-info, .footer-contact {
      display: inline-block;
    }

    .copyright-info {
      text-align: left;
    }

    .footer-contact {
      text-align: right;
    }
  }

  @media (min-width: $smMinWidth) and (max-width: $lgMinWidth) {
    .copyright-info, .footer-contact {
      width: 47%;
    }

    .copyright-info {
      margin-left: 15px;
    }

    .footer-contact {
      margin-right: 15px;
    }
  }

  @media(min-width: $lgMinWidth) {
    .copyright-info, .footer-contact {
      width: 50%;
    }
  }
}
