/// Mixin to prefix a property
/// @param {Map} $declarations - Declarations to prefix
/// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefixProp($declarations, $prefixes: ()) {
  @each $property,
  $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }

    #{$property}: $value;
  }
}

/// Mixin to prefix a value
/// @param {Map} $declarations - Declarations to prefix
/// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefixValue($declarations, $prefixes: ()) {
  @each $property,
  $value in $declarations {
    @each $prefix in $prefixes {
      #{$property}: '-' + $prefix + '-' + $value;
    }

    #{$property}: $value;
  }
}

/// Mixin to prefix keyframes
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
